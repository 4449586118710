import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { patientDataPending } from '../../../../store/patientData/actions';
import { communicationUpdate, profileUpdate } from '../../../services/profile';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
export const CommPreferenceEdit = ({ handleModalClose, handleSuccess, user }: any) => {
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            contactmethod: '',
            globalError: ''
        }
    });
    useEffect(() => {
        if (user && user.communication) {
            setValue('contactmethod', user.communication.peference);
        }
    }, [user]);
    const handleUpdatePrefrences = (data: any) => {
        if (data.contactmethod === user.communication.peference) {
            setError('globalError', { type: 'custom', message: 'Old contact method and new contact method both are same.' });
            return;
        }
        setIsLoading(true);
        communicationUpdate({
                type: 'contactmethod',
                contactmethod: data.contactmethod,
                dependantId:user.dependantId,
                guarantorId:user.guarantor
            }).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success("Contact method updated successfully");
                    handleSuccess();
                    dispatch(patientDataPending({
                        uniqueId: userData.uniqueId
                    }));
                }
            }).catch((err) => {
                setIsLoading(false);
                toast.success("Something went wrong.");
            });
        
    }
    return (
        <>
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    // data-bs-dismiss="offcanvas" aria-label="Close"
                    onClick={() => { handleModalClose() }}>
                    Done
                </button>
            </div>

            <div className="offcanvas-header">
                <h5 className="offcanvas-title">Communication Preference</h5>
            </div>

            <div className="offcanvas-body">

                <div className="form-check">
                    <input className="form-check-input" type="radio" name="contactmethod" id="preEmail" value="email" ref={register({ required: true })} />
                    <label className="form-check-label" htmlFor="preEmail">Email</label>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="radio" name="contactmethod" id="preText" value="text" ref={register({ required: true })} />
                    <label className="form-check-label" htmlFor="preText">Text</label>
                </div>

                <div className="form-check mb-3">
                    <input className="form-check-input" type="radio" name="contactmethod" id="preBoth" value="email,text" ref={register({ required: true })} />
                    <label className="form-check-label" htmlFor="preBoth">Text & Email</label>
                </div>

                <div className="mb-3">
                    <button type="button" className="btn btn-dark w-100 fw-normal" onClick={handleSubmit(handleUpdatePrefrences)}>
                        {
                            (isLoading) ?
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                                :
                                'Save'
                        }
                    </button>
                </div>

            </div>
        </>
    )
}
