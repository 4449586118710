import React, { useMemo, useState } from 'react';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { LoyaltyActivity } from './LoyaltyActivity';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { joinLoyalty } from '../../../services/loyalty';
import { patientDataPending } from '../../../../store/patientData/actions';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

export const XcarePoints = (props: any) => {
    const [isShowReferFriend, setIsShowReferFriend] = useState(false);
    const [joinLoyaltyLoader, setJoinLoyaltyLoader] = useState(false);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const currentDependant = useMemo(() => {
        const ID = patientData.id;
        return patientData?.dependant?.find((item: any) => Number(item.loginId) === Number(ID));
    }, [patientData]);
    const totalSum = useMemo(() => {
        let sum = 0;
        patientData?.dependant?.forEach((item: any) => {
            if (item.points && Number.isFinite(Number(item.points))) {
                sum += Number(item.points);
            }
        });
        return sum;
    }, [patientData]);
    const joinLoyaltyProgram = async () => {
        try {
            setJoinLoyaltyLoader(true);
            const success = await joinLoyalty(currentDependant?.id);
            setJoinLoyaltyLoader(false)
            if (success && success.status && success.status.error === false) {
                dispatch(patientDataPending({ uniqueId: patientData?.uniqueId }));
            }
        } catch (err) {
            setJoinLoyaltyLoader(false);
            toast.error('We are unable to enrol you, please try again after sometime.')
        }
    };
    return (
        <>

            {
                (currentDependant?.memberId === "0" || currentDependant?.memberId === null || 
                currentDependant?.memberId === "null" || currentDependant?.memberId === "") ?
                <div className="card_item rew_loy_point card p-3">
                    <div className="infocard_header mb-2">
                        <div className="card_ititle">Loyalty Program</div>
                    </div>
                    <div className="card_ilink">
                        {
                            joinLoyaltyLoader ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> :
                            <button type="button" className="btn btn-link p-0 fw-normal fs-7 me-3" onClick={() => { 
                                joinLoyaltyProgram();
                            }}>Join Program</button>
                        }
                    </div>
                </div> :
                <div className="card_item rew_loy_point card p-3">
                    <div className="infocard_header mb-2">
                        <div className="card_ititle">Xcare Points - {totalSum}</div>
                        <div className="card_desc opacity-75">
                            Account #{currentDependant?.memberId}
                        </div>
                    </div>
                    <div className="card_ilink fix_bottom">
                        <button type="button" className="btn btn-link p-0 fw-normal fs-7 me-3" onClick={() => { setIsShowReferFriend(true) }}>View Points Activity</button>
                    </div>
                </div>        
            }
            {/* 
                <div className="card_item rew_loy_point card p-3">
                    <div className="infocard_header d-flex justify-content-between mb-3">
                        <div className="card_ititle">Your Xcare Points</div>
                        <div className="card_ilink">
                            <button type="button" className="btn btn-link p-0 fw-normal fs-7">Redeem Now</button>
                        </div>
                    </div>
                    <div className="infocard_body">
                        <div className="d-flex">
                            <div className="rewardsp me-3 pe-3 border-end">
                                <div className="fs-7">Rewards: 5000 pt.</div>
                                <div className="fs-4">$50.00</div>
                            </div>

                            <div className="loyalp">
                                <div className="fs-7">Loyalty: 4000 pt.</div>
                                <div className="fs-4">$40.00</div>
                            </div>
                        </div>
                    </div>
                </div> */}

            {/* <ReferFriend isShow={isShowReferFriend} handleClose={() => { setIsShowReferFriend(false) }} /> */}
            <SharedOffCanvas
                isOpen={isShowReferFriend}
                handleClose={() => { setIsShowReferFriend(false) }}
                Children={LoyaltyActivity}
                handleSuccess={() => {
                    setIsShowReferFriend(false)
                }}
                style={{
                    width: '70vw'
                }}
            />
        </>
    )
}
