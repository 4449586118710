import React from 'react';
import { isLogin, getCookie } from '../../utils';
import { Redirect, Switch, Route } from 'react-router-dom';
import Refer from '../../components/refer/Refer';
import Account from '../../components/account/Account';
import ClaimReward from '../../components/claimReward/ClaimReward';
import Appointments from '../../components/appointments/Appointments';
import AppointmentDetail from '../../v2/pages/appointmentDetail/AppointmentDetail';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { userLogout, loginSuccess, loginPending, showWaitingPresc, showWaitingPayment } from '../../store/auth/actions';
import { AuthState, LoginRequest, LoginResponse } from '../../store/auth/types';
import { PatientDataRequest, PatientDataState } from '../../store/patientData/types';
import { patientDataPending } from '../../store/patientData/actions';
import { LoadingState } from '../../store/loadingIndicator/types';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import ClaimRewardStatus from '../../components/claimReward/claimRewardStatus/ClaimRewardStatus';
import Cookie from 'js-cookie';
import { toast } from 'react-toastify';
import MyCoverage from '../../components/myCoverage/MyCoverage';
import SearchProvider from '../../components/searchProvider/SearchProvider';
import ClinicProfile from '../../components/clinic/ClinicProfile';
import MyClinic from '../../components/myClinic/MyClinic';
import { getCurrentUser, signInWithCustomToken } from '../../utils/firebaseUtils';
import $ from 'jquery';
import { useQuery } from '../../utils/global';
import MyFamilyV2 from '../../v2/pages/myfamily/MyFamily';
import { Dashboard } from '../../v2/pages/dashboard/dashboard';
import { Header } from '../../v2/components/header/Header';
import { Sidebar } from '../../v2/components/sidebar/Sidebar';
import { AccountV2 } from '../../v2/pages/account/Account';
import { PreScreeningThanks } from '../../v2/pages/preScreening/PreScreeningThanks';
import GroupPinList from '../../v2/pages/groupPin/GroupPinList';
import { ChatRoutes } from '../../v2/pages/chat/ChatRoutes';
import { VoiceCall } from '../../v2/pages/voicecall/VoiceCall';
import { RecurringPayment } from '../../v2/pages/payment/Payment';
import { DashboardNew } from '../../v2/pages/dashboardNew/DashboardNew';
import { Map } from '../../v2/pages/map/Map';
import { Pins } from '../../v2/pages/pins/Pins';
import { Footer } from '../../v2/components/footer/Footer';
import axios from 'axios';
import Offers from '../../components/offers/Offers';
const Payment = React.lazy(() => import('../../components/payment/Payment'));
interface Props {
    location: any;
    logout: typeof userLogout;
    authState: AuthState;
    login: typeof loginSuccess;
    patientDataState: PatientDataState;
    patientData: typeof patientDataPending;
    loadingState: LoadingState;
    loginPending: typeof loginPending;
    history: any;
    showWaitingPresc: typeof showWaitingPresc;
    showWaitingPayment: typeof showWaitingPayment;
}
interface State {
    validLogin: boolean;
    isDataLoaded: boolean;
    isLoading: boolean;
    isLogin: any;
}
class MainContainer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            validLogin: true,
            isDataLoaded: false,
            isLoading: false,
            isLogin: ''
        }
    }
    componentDidMount() {
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.replace('/');
            }
            return error;
        }); 
        if (Cookie.getJSON('patient_cookie') === undefined || Cookie.getJSON('patient_cookie') === null) {
            // toast.warn('Please check cookies are enabled or not, we require cookie to use the website features.');
            if (useQuery(this.props.location).get('sessId') && useQuery(this.props.location).get('sessId') !== "") {
                this.props.history.replace('/login', { 
                    sessId: useQuery(this.props.location).get('sessId'), 
                    id: useQuery(this.props.location).get('id'), 
                    webview: useQuery(this.props.location).get('webview'), 
                    medium:useQuery(this.props.location).get('medium'),
                    mode:useQuery(this.props.location).get('mode'),
                });
                return;
            } else {
                this.setState({ validLogin: false });
            }
        } else if (isLogin()) {
            this.loginOnFocus();
        } else {
            if (useQuery(this.props.location).get('sessId') && useQuery(this.props.location).get('sessId') !== "") {
                this.props.history.replace('/login', { 
                    sessId: useQuery(this.props.location).get('sessId'), 
                    id: useQuery(this.props.location).get('id'), 
                    webview: useQuery(this.props.location).get('webview'), 
                    medium:useQuery(this.props.location).get('medium'),
                    mode:useQuery(this.props.location).get('mode'),
                });
                return;
            }
            this.setState({ validLogin: false });
        }
        // responsive menu
        $('a.target-burger').click(function (e) {
            $('nav.mobile-nav, a.target-burger, body').toggleClass('toggled');
            e.preventDefault();
            $('.mobile-nav .menu li:has(ul)').append('<span class="dsarrow"></span>');

            $('.mobile-nav .sub-menu').slideUp();
            $(".mobile-nav .menu li:has(ul)").find(".dsarrow").click(function () {
                // if(false == $(this).parent().children(".mobile-nav .menu li ul.sub-menu").is(':visible')) {
                //     // $('.mobile-nav .menu li ul.sub-menu').slideUp();
                // }
                $(this).parent().children(".mobile-nav .menu li ul.sub-menu").slideToggle();
            });
        });
        $('.mobile-nav .navbar-nav .nav-item .nav-link').click(function () {
            $("nav.mobile-nav").removeClass("toggled");
            $("a.target-burger").removeClass("toggled");
        })
        if (this.props.location.pathname === '/search-provider') {
            window.addEventListener('focus', this.onFocus);
        }
    }
    onFocus = () => {
        if (this.props.location.pathname === '/search-provider') {
            if (!isLogin()) {
                this.props.logout();
                this.props.showWaitingPresc(false);
                this.props.showWaitingPayment(false);
                this.setState({ validLogin: false });
            } else {
                this.loginOnFocus();
            }
        }
    }

    loginOnFocus = () => {
        const login: any = getCookie();
        if (useQuery(this.props.location).get('sessId')) {
            this.props.logout();
            this.props.history.replace('/login', { 
                sessId: useQuery(this.props.location).get('sessId'), 
                id: useQuery(this.props.location).get('id'), 
                webview: useQuery(this.props.location).get('webview'),
                medium:useQuery(this.props.location).get('medium'),
                mode:useQuery(this.props.location).get('mode'),
            });
        }
        if (!getCurrentUser()) {
            console.log('notCurrent');
            try {
                if (useQuery(this.props.location).get('sessId') && useQuery(this.props.location).get('mode') === "prescreening") {
                    this.props.showWaitingPresc(true);
                } else if (useQuery(this.props.location).get('sessId') && useQuery(this.props.location).get('mode') === "payment") {
                    this.props.showWaitingPayment(true);
                }
                signInWithCustomToken(getCookie().jwtToken).then((signInSuccess) => {
                    console.log('loginDetails', login);
                    this.props.login(login);
                    // this.props.patientData({ userId: login.id, uniqueId: login.uniqueId });
                    this.setState({ isLoading: true });
                    this.props.patientData({ uniqueId: login.xpr_user_id });
                    this.setState({ validLogin: true });
                }).catch((error) => {
                    this.props.logout();
                    this.props.showWaitingPresc(false);
                    this.props.showWaitingPayment(false);
                    this.setState({ validLogin: false });
                })
            } catch (err) {
                this.props.logout();
                this.props.showWaitingPresc(false);
                this.props.showWaitingPayment(false);
                this.setState({ validLogin: false });
            }
        } else {
            this.props.login(login);
            this.setState({ isLoading: true });
            // this.props.patientData({ userId: login.id, uniqueId: login.uniqueId });
            this.props.patientData({ uniqueId: login.xpr_user_id });
            this.setState({ validLogin: true });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (nextProps !== this.props) {
            if (nextProps.loadingState.pending !== this.props.loadingState.pending) {
                if (nextProps.loadingState.pending === true) {
                    this.setState({ isLoading: true });
                } else {
                    this.setState({ isLoading: false });
                }
            }
            if (nextProps.patientDataState.error !== null) {
                // toast.error('Something went wrong.');
                this.setState({ isLoading: false });
            } else {
                if (nextProps.patientDataState.pending === false) {
                    if (nextProps.patientDataState.patientDataResponse && nextProps.patientDataState.patientDataResponse.status
                        && nextProps.patientDataState.patientDataResponse.status.error === false) {
                        this.setState({ isDataLoaded: true, isLoading: false });
                    }
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.patientDataState.error = null;
        if (this.props.patientDataState.patientDataResponse) {
            this.props.patientDataState.patientDataResponse.status.error = true;
        }
    }
    userLogoutClick() {
        this.props.logout();
        this.setState({ validLogin: false });
    }
    render() {
        console.log(this.props.location);
        if (this.state.validLogin === false) {
            return (
                <div className="app">
                    <Redirect to="/login"></Redirect>
                </div>
            );
        } else {
            let letterOne = '';
            let letterTwo = '';
            if (this.props.patientDataState && this.props.patientDataState.patientDataResponse &&
                this.props.patientDataState.patientDataResponse.firstName &&
                this.props.patientDataState.patientDataResponse.firstName !== "" &&
                this.props.patientDataState.patientDataResponse.lastName &&
                this.props.patientDataState.patientDataResponse.lastName !== "") {
                letterOne = this.props.patientDataState.patientDataResponse.firstName.charAt(0).toUpperCase();
                letterTwo = this.props.patientDataState.patientDataResponse.lastName.charAt(0).toUpperCase();
            }
            return (
                <>
                    {
                        this.state.isLoading === true &&
                        <LoadingIndicator></LoadingIndicator>
                    }
                    {
                        this.state.isDataLoaded === true &&
                        <>
                            {
                                !this.props.location.pathname.includes('/exitapp') &&
                                <Header />
                            }
                            {
                                isLogin() &&

                                <VoiceCall></VoiceCall>
                            }
                            {/* {
                                !this.props.location.pathname.includes('/appointmentdetail/') && !this.props.location.pathname.includes('/exitapp') && !this.props.location.pathname.includes('/board') &&
                                <Sidebar />
                            } */}
                            <Switch>
                                <Route path='/board' component={DashboardNew}></Route>
                                <Route path='/map' component={Map}></Route>
                                <Route path='/dashboard' component={Dashboard}></Route>
                                <Route path='/user' component={AccountV2}></Route>
                                <Route path="/chat" component={ChatRoutes}></Route>
                                <Route exact path="/exitapp" component={PreScreeningThanks} />
                                <Route exact path="/myfamily" component={MyFamilyV2}></Route>
                                <Route exact path="/referrals" component={Refer}></Route>
                                <Route exact path="/offers" component={Offers}></Route>
                                <Route exact path="/claim-reward" component={ClaimReward}></Route>
                                <Route exact path="/appointments" component={Appointments}></Route>
                                <Route exact path="/appointmentdetail/:appointmentId" component={AppointmentDetail}></Route>
                                <Route exact path="/account" component={Account}></Route>
                                <Route exact path="/claim-reward-status" component={ClaimRewardStatus}></Route>
                                <Route exact path="/my-coverage" component={MyCoverage}></Route>
                                <Route exact path="/search-provider" component={SearchProvider}></Route>
                                <Route exact path="/clinic/:Id" component={ClinicProfile}></Route>
                                <Route exact path="/my-clinics" component={MyClinic}></Route>
                                <Route exact path="/appointment/payment/:Id" render={(props) => (
                                    <React.Suspense fallback={<div />}>
                                        <RecurringPayment {...props} />
                                        {/* <Payment {...props} /> */}
                                    </React.Suspense>
                                )}></Route>
                                <Route exact path="/">
                                    <Redirect to="/board"></Redirect>
                                </Route>
                            </Switch>
                            {
                                isLogin() &&
                                <div className="fab-bottom">
                                    {/* <GroupPinList></GroupPinList> */}
                                    <Pins />
                                </div>
                            }
                            {
                                !this.props.location.pathname.includes('/exitapp') &&
                                <Footer />
                            }

                        </>
                    }
                </>
            )
        }
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    loginPending: (request: LoginRequest) => {
        dispatch(loginPending(request));
    },
    login: (request: LoginResponse) => {
        dispatch(loginSuccess(request));
    },
    logout: () => {
        dispatch(userLogout());
    },
    patientData: (request: PatientDataRequest) => {
        dispatch(patientDataPending(request));
    },
    showWaitingPresc: (status: boolean) => {
        dispatch(showWaitingPresc(status));
    },
    showWaitingPayment: (status: boolean) => {
        dispatch(showWaitingPayment(status));
    }
});
const mapStateToProps = (state: IAppState) => ({
    authState: state.auth,
    patientDataState: state.patientData,
    loadingState: state.loading,
});
export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);