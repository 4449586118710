const DEV_HOST = ['patient.dev.xcarepro.com'];
const STAGING_HOST = ['patient.staging.xcarepro.com', 'patient.stagingus.xcarepro.com', 'localhost'];
const PROD_HOST = ['patient.xcarepro.com', 'patient.us.xcarepro.com'];
const hostname = window.location.hostname;
let configs: any = {};
if (DEV_HOST.findIndex(item => { return item === hostname }) !== -1) {
    //Dev Mode
    // const SOURCE = "https://staging.xcarepro.com/";
    const SOURCE = "https://dev.xcarepro.com/";
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'selfcare/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        MESSAGE_CENTER_API: 'https://mc.dev.xcarepro.com/api/',
        GOOGLE_API_KEY: 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8',
        STRIPE_PUBLIC_KEY: 'pk_test_lDXsJpSjBU246cZtxswWkRsx002OC42goy',
        COOKIE_DOMAIN: '.dev.xcarepro.com',
        PATIENT_URL: 'https://patient.dev.xcarepro.com/',
        FRONT_URL: 'https://dev.xcarepro.com/',
        CHAT_COLLECTION_NAME: 'chat_dev', //chat for staging and chat_live for live.
        CONVERSATION_DEV: 'conversation_dev',
        GROUP_COLLECTION: 'groups_dev',
        USER_COLLECTION: 'users_dev',
        MESSAGE_COLLECTION: 'messages_dev',
        COMMUNICATION_SERVER: 'https://comu.dev.xcarepro.com/api/'
    };
} else if (STAGING_HOST.findIndex(item => { return item === hostname }) !== -1) {
    //staging Mode
    let SOURCE = "https://staging.xcarepro.com/";
    if (window.location.hostname === "patient.stagingus.xcarepro.com") {
        SOURCE = "https://stagingus.xcarepro.com/";
    }
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'selfcare/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        MESSAGE_CENTER_API: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'https://mc.stagingus.xcarepro.com/api/' : 'https://mc.staging.xcarepro.com/api/',
        GOOGLE_API_KEY: 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8',
        STRIPE_PUBLIC_KEY: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'pk_test_51LHoUvBtaGIwamZqv6MNfaoAc4Isc6g9qjJYfBPw3vSs3FgrBXc74p3v98DWLj3DcjguBaYIYHVGL5V2wA1dFIwr00qpFlfC9F' : 'pk_test_lDXsJpSjBU246cZtxswWkRsx002OC42goy',
        COOKIE_DOMAIN: window.location.hostname === "patient.stagingus.xcarepro.com" ? '.stagingus.xcarepro.com' : '.staging.xcarepro.com',
        PATIENT_URL: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'https://patient.stagingus.xcarepro.com' : 'https://patient.staging.xcarepro.com/',
        FRONT_URL: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'https://stagingus.xcarepro.com/' : 'https://staging.xcarepro.com/',
        CHAT_COLLECTION_NAME: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'us_chat' : 'chat', //chat for staging and chat_live for live.
        CONVERSATION_DEV:  window.location.hostname === "patient.stagingus.xcarepro.com" ? 'us_conversation_staging' : 'conversation_staging',
        GROUP_COLLECTION: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'us_groups_staging' : 'groups_staging',
        USER_COLLECTION: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'us_users_staging' : 'users_staging',
        MESSAGE_COLLECTION: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'us_messages_staging' : 'messages_staging',
        COMMUNICATION_SERVER: window.location.hostname === "patient.stagingus.xcarepro.com" ? 'https://comu.stagingus.xcarepro.com/api/' : 'https://comu.staging.xcarepro.com/api/'
    };
} else if (PROD_HOST.findIndex(item => { return item === hostname }) !== -1) {
    //Prod Mode
    let SOURCE = "https://www.xcarepro.com/";
    if (window.location.hostname === "patient.us.xcarepro.com") {
        SOURCE = "https://us.xcarepro.com/";
    }
    configs = {
        SOURCE_URL: SOURCE,
        API_URL: SOURCE + 'selfcare/',
        IMAGE_URL: SOURCE + 'uploads/',
        DEFAULT_IMAGE_URL_DOCTOR: SOURCE + 'uploads/doctor/default_doctor_photo.jpg',
        DEFAULT_IMAGE_URL_CLINIC: SOURCE + 'uploads/clinic/default_clinic_logo.jpg',
        MESSAGE_CENTER_API: window.location.hostname === "patient.us.xcarepro.com" ? 'https://mc.us.xcarepro.com/api/' : 'https://mc.xcarepro.com/api/',
        GOOGLE_API_KEY: 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8',
        STRIPE_PUBLIC_KEY: window.location.hostname === "patient.us.xcarepro.com" ? 'pk_live_51LHoUvBtaGIwamZqsFwSmYB3AFx53aKz3aW8DbcRfG71xjsTj6gUE4TpppwQSAaRuLJsqkfGLeWbwn08Bf7r6fbW00zSOJzuGQ' : 'pk_live_kiZnBAq9UhZ51XE48yE4PcN0008A3aLh2Z',
        COOKIE_DOMAIN: '.xcarepro.com',
        PATIENT_URL: window.location.hostname === "patient.us.xcarepro.com" ? 'https://patient.us.xcarepro.com/' : 'https://patient.xcarepro.com/',
        FRONT_URL: window.location.hostname === "patient.us.xcarepro.com" ? 'https://us.xcarepro.com/' : 'https://www.xcarepro.com/',
        CHAT_COLLECTION_NAME: window.location.hostname === "patient.us.xcarepro.com" ? 'us_chat_live' : 'chat_live', //chat for staging and chat_live for live.
        CONVERSATION_DEV: window.location.hostname === "patient.us.xcarepro.com" ? 'us_conversation_live' : 'conversation_live',
        GROUP_COLLECTION: window.location.hostname === "patient.us.xcarepro.com" ? 'us_groups_live' : 'groups_live',
        USER_COLLECTION: window.location.hostname === "patient.us.xcarepro.com" ? 'us_users_live' : 'users_live',
        MESSAGE_COLLECTION: window.location.hostname === "patient.us.xcarepro.com" ? 'us_messages_live' : 'messages_live',
        COMMUNICATION_SERVER: window.location.hostname === "patient.us.xcarepro.com" ? 'https://comu.us.xcarepro.com/api/' : 'https://comu.xcarepro.com/api/'
    };
}
export default configs;
